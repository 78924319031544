<template>
    <div class="app">
        <div class="app__main-block">
            <HeaderComponent />
            <MainPromo />
        </div>
        <div class="app__what-developing what-developing">
            <a id="services" />
            <InfoSection title="Что мы разрабатываем?">
                <el-row :gutter="10" class="what-developing__items">
                    <el-col :span="24" :sm="12" :md="6">
                        <InfoBlock title="МОБИЛЬНЫЕ И ВЕБ-ИНТЕРФЕЙСЫ"
                                   :click="openDetailModal"
                                   clickable
                                   min-height="600px"
                                   description="Индивидуальные системы, призванные автоматизировать рутинные процессы вашего бизнеса"
                                   image-position="center 250px"
                                   image="images/mobile.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>Мы обладаем значительным опытом разработки как обычных сайтов, так и сложных веб-приложений. В своей работе мы применяем современные технологии, такие как Vue и ORM, что позволяет нам создавать качественные продукты в сжатые сроки.</p>
                                    <p>Мы также разрабатываем мобильные приложения на базе Web-технологий, что снижает затраты на создание отдельных приложений для Android и iOS. У нас есть опыт интеграции с мобильным приложением <a href="https://nexta-it-b24.bitrix24site.ru/#b388971" target="_blank" title="Битрикс24">Битрикс24</a>, что даёт возможность разрабатывать собственный функционал в привычном для сотрудников интерфейсе корпоративного мобильного приложения.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :sm="12" :md="6">
                        <InfoBlock title="УНИКАЛЬНЫЕ CRM-СИСТЕМЫ ПОД ЛЮБОЙ БИЗНЕС НА БАЗЕ БИТРИКС24"
                                   clickable
                                   :click="openDetailModal"
                                   min-height="600px"
                                   description="Приложения под ваши нужды, которые помогут вам увеличить продажи и прибыль"
                                   image-position="center 250px"
                                   image-size="120%"
                                   image="images/crm.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>В большинстве случаев компаниям достаточно стандартного функционала и настроек CRM на базе <a href="https://nexta-it-b24.bitrix24site.ru/#b388971" target="_blank" title="Битрикс24">Битрикс24</a>. Однако иногда бизнесу требуется более удобное приложение, специализированное под его нужды.</p>
                                    <p>Например, мы интегрировались с мобильным приложением <a href="https://nexta-it-b24.bitrix24site.ru/#b388971" target="_blank" title="Битрикс24">Битрикс24</a> и разработали удобный интерфейс для полевых сотрудников — менеджеров по работе с ключевыми клиентами (Key Account Manager), которые посещают лечебно-профилактические учреждения (ЛПУ). Это приложение позволяет планировать визиты в ЛПУ и встречи с медицинскими работниками, фиксировать результаты встреч и посещений, а также записывать геолокацию и продолжительность визитов.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :sm="12" :md="6">
                        <InfoBlock title="ИНТЕГРАЦИЯ C СИСТЕМАМИ УЧЕТА (В ЧАСТНОСТИ 1С)"
                                   clickable
                                   :click="openDetailModal"
                                   min-height="600px"
                                   description="Простая передача данных в двух направлениях без долгого ожидания даже в условиях большого кол-ва источников и данных"
                                   image-position="-30px 250px"
                                   image="images/integration.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>У нас есть опыт разработки специализированного API на стороне приложения 1С. Например, мы можем создать набор Web- или HTTP-сервисов, которые будут вызывать сторонние приложения для обмена данными с приложением 1C.</p>
                                    <p>Кроме того, в нашей команде есть BI-аналитики, обладающие глубокими знаниями структуры таблиц в 1C и способные создать любое представление (View) на уровне базы данных.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :sm="12" :md="6">
                        <InfoBlock title="АНАЛИТИЧЕСКИЕ ОТЧЕТЫ"
                                   clickable
                                   :click="openDetailModal"
                                   min-height="600px"
                                   description="Мгновенное получение срезов нужной вам информации из любых систем в доступном виде. Можем разрабатывать BI приложения или встраивать диаграммы прямо на веб страницах"
                                   image-position="-60px 260px"
                                   image-size="130%"
                                   image="images/analytics.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>Мы разрабатываем комплексные системы управленческой отчетности на BI-платформе, которая дает возможность в режиме онлайн анализировать любую информацию, имеющуюся в компании.</p>
                                    <p>Благодаря современным средствам визуализации данных руководители смогут получать ключевую аналитику, позволяющую принимать стратегические решения в кратчайшие сроки. Аналитики же получат возможность изучать большие массивы данных, отслеживать тренды, находить новые точки роста для компании.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                </el-row>
            </InfoSection>
        </div>
        <div class="slogan-section">
            <div class="slogan-section__content">
                <div class="slogan-section__text">
                    Мы превращаем сложные задачи в <span class="slogan-section__key-word">простые решения!</span>
                </div>
            </div>
        </div>
        <div class="services">
            <div class="services__content">
                <el-row :gutter="10" class="services__items">
                    <el-col :span="24" :md="8">
                        <SimpleInfoBlock title="Прозрачная разработка"
                                         description="Максимально прозрачное производство проекта, с регулярными отчётами и дальнейшим внедрением">
                            <template #icon>
                                <CodeIcon />
                            </template>
                        </SimpleInfoBlock>
                    </el-col>
                    <el-col :span="24" :md="8">
                        <SimpleInfoBlock title="Поддержка и обучение"
                                         description="Так как любое приложение будет вашим сотрудникам в новинку, мы не бросим их разбираться самостоятельно">
                            <template #icon>
                                <Cube />
                            </template>
                        </SimpleInfoBlock>
                    </el-col>
                    <el-col :span="24" :md="8">
                        <SimpleInfoBlock title="Консультации и развитие"
                                         description="Собираем отзывы от работающих с приложением, анализируем и предлагаем план развития проекта">
                            <template #icon>
                                <Chart />
                            </template>
                        </SimpleInfoBlock>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="app__cases cases">
            <a id="cases" />
            <InfoSection title="Примеры наших кейсов">
                <el-row :gutter="10" class="cases__items">
                    <el-col :span="24" :md="8">
                        <InfoBlock title="Согласование и закрытие командировок"
                                   clickable
                                   :click="openDetailModal"
                                   min-height="500px"
                                   image-position="15px 130px"
                                   image-size="110%"
                                   image="images/business_trips.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>В интерфейсе можно указать информацию об отелях, билетах, встречах и других деталях. Также есть возможность посмотреть аналитику по другим командировкам в этот город: их количество и затраты. Можно также увидеть выполнение планов сотрудников в этом городе.</p>
                                    <p>Кроме того, в интерфейсе доступен просмотр оставшегося бюджета на командировки.</p>
                                    <p>После создания заявки запускается гибкий процесс согласования и получения закрывающих документов по командировке. Процесс учитывает оставшийся бюджет и суммы командировок для более оптимальной цепочки согласующих.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :md="8">
                        <InfoBlock title="Система выставления коммерческих предложений"
                                   clickable
                                   :click="openDetailModal"
                                   min-height="500px"
                                   image-position="center 150px"
                                   image-size="130%"
                                   image="images/commercial.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>Реализована система выгрузки данных о закупках лекарственных препаратов лечебно-профилактическими учреждениями. Разработан удобный интерфейс для быстрого поиска информации, мониторинга и контроля распределения закупок между менеджерами.</p>
                                    <p>Также создан интерфейс для формирования условий коммерческого предложения (КП) и определён бизнес-процесс согласования этих условий с последующей отправкой дистрибьюторам.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :md="8">
                        <InfoBlock title="Система прогнозирования и планирования производства"
                                   clickable
                                   :click="openDetailModal"
                                   min-height="500px"
                                   image-position="center 150px"
                                   image-size="110%"
                                   image="images/production.png">
                            <template #detail>
                                <div class="detail-content">
                                    <p>Интерфейс где ответственные за регион директора планируют отгрузки препаратов на 3 месяца с учетом прошлых прогнозов и индикаторов получаемых на основании данных из 1С.</p>
                                    <p>Бизнес-процесс позволяющий в быстрые сроки согласовать производство нужных препаратов.</p>
                                    <p>Аналитика по остаткам из 1С и прогнозами уходимости на основании которого можно принять решение по целесообразности производства предлагаемого объема.</p>
                                </div>
                            </template>
                        </InfoBlock>
                    </el-col>
                </el-row>
                <div class="cases__button">
                    <Btn style="width: 100%;"
                         @click="goToAnchor('feedback')"
                         size="large"
                         title="Связаться с нами" />
                </div>
            </InfoSection>
        </div>
        <div class="app__services-cost services-cost">
            <a id="services-cost" />
            <InfoSection title="Стоимость наших услуг">
                <el-row :gutter="10" class="services-cost__items">
                    <el-col :span="24" :md="8">
                        <InfoBlock title="от 1 500 руб/час"
                                   title-font-size="36px">
                            <template #content>
                                <ul class="check-list">
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Верстка</span>
                                    </li>
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Дизайн</span>
                                    </li>
                                </ul>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :md="8">
                        <InfoBlock title="от 2 000 руб/час"
                                   title-font-size="36px">
                            <template #content>
                                <ul class="check-list">
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Настройка CRM</span>
                                    </li>
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Автоматизация цепочек согласования бизнес процесса</span>
                                    </li>
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Настройка форм для заполнения данных</span>
                                    </li>
                                </ul>
                            </template>
                        </InfoBlock>
                    </el-col>
                    <el-col :span="24" :md="8">
                        <InfoBlock title="от 2 500 руб/час"
                                   title-font-size="36px">
                            <template #content>
                                <ul class="check-list">
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Создания сложных мобильных и веб приложений</span>
                                    </li>
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Синхронизация данных с другими источниками (например 1С)</span>
                                    </li>
                                    <li class="check-list__item">
                                        <Accept width="15"
                                                height="15"
                                                class="check-list__icon" />
                                        <span>Разработка BI cистем</span>
                                    </li>
                                </ul>
                            </template>
                        </InfoBlock>
                    </el-col>
                </el-row>
                <div class="services-cost__button">
                    <Btn style="width: 100%;"
                         @click="goToAnchor('feedback')"
                         size="large"
                         title="Расчитать проект под ключ" />
                </div>
            </InfoSection>
        </div>
        <div class="app__feedback-section">
            <a id="feedback" />
            <FeedbackForm title="Отправить заявку, чтобы мы могли с вами связаться" />
        </div>
        <div class="faq-section">
            <InfoSection title="Часто задаваемые вопросы"
                         title-position="center">
                <div class="faq-section__items">
<!--                    <CollapseItem title="Какая стоимость ваших услуг?">-->
<!--                        Стоимость услуг зависит от привлекаемых в проекте специалистов <br>-->
<!--                        <br>-->
<!--                        <b>2 000 руб/час</b> - Настройка CRM, автоматизация цепочек согласования бизнес процесса, настройка форм для заполнения данных<br>-->
<!--                        <br>-->
<!--                        <b>2 500 руб/час</b> - Создания сложных мобильных и веб приложений, Синхронизация данных с другими источниками (например 1С), разработка BI cистем.<br>-->
<!--                        <br>-->
<!--                        <b>1 500 руб/час</b> - Верстка и дизайн-->
<!--                    </CollapseItem>-->
                    <CollapseItem title="Какие системы для бизнеса вы можете порекомендовать и какой у вас есть опыт работы с ними?">
                        <b>Учетные системы:</b> Больше всего у нас опыта в интеграциях с продуктами 1С-ERP, 1С-Бухгалтерия, 1С-ЗУП.<br>
                        <br>
                        <b>CRM системы и Корпоративные порталы:</b> В настоящее время мы не видим продукта, который мог бы составить серьёзную конкуренцию <a href="https://nexta-it-b24.bitrix24site.ru/#b388971" target="_blank" title="Битрикс24">Битрикс24</a>, особенно его коробочной версии.<br>
                        <br>
                        <b>BI системы:</b> Наш выбор пал на продукт Qlik sense из за быстрого порога входа
                        разработчиков, дополнительной прослойки модели данных и открытому исходному коду диаграмм, благодаря чему можно доработать или сделать любую нужную диаграмму. Также мы наблюдаем за развитием встроенной в Битрикс24 BI системой. Хорошая альтернатива если бизнес еще не созрел до отдельного продукта, а Битрикс24 у них уже есть.
                    </CollapseItem>

                    <CollapseItem title="Какие технологии вы используете в своей работе?">
                        Для создания сайтов и веб приложений мы используем:<br>
                        <br>
                        <b>Bitrix Framework</b> — это платформа для создания сайтов и приложений, основанная на PHP.<br>
                        <b>Laravel</b> — PHP-фреймворк, который используется для разработки веб-приложений.<br>
                        <b>Django</b> — Python-фреймворк для разработки веб-приложений.<br>
                        <br>
                        Для создания визуальной части веб-приложения мы используем <b>JavaScript</b>, <b>Vue.js</b>, <b>HTML</b> и <b>CSS</b>. При необходимости можем работать с библиотекой <b>jQuery</b>.<br>
                        <br>
                        Для работы с данными и разработки BI-аналитики мы используем <b>Qlik Sense</b> и <b>SQL</b>.<br>
                        <br>
                        Прочие инструменты и библиотеки, используемые в работе: <b>Git</b>, <b>Selenium</b>, <b>Element Plus</b>, <b>Bootstrap</b>.
                    </CollapseItem>

<!--                    <CollapseItem title="Какие языки программирования и технологии используете в работе?">-->
<!--                        <b>Битрикс24:</b> Мы разрабатываем сайты на платформе Битрикс24, которая обеспечивает надежность, безопасность и высокую производительность. Это идеальное решение для бизнеса, которое позволяет интегрировать все ваши процессы в одном месте.-->
<!--                        <br>-->
<!--                        <br>-->
<!--                        <b>PHP:</b> В качестве основного языка программирования мы используем PHP — мощный инструмент для создания динамических веб-приложений. PHP позволяет нам разрабатывать масштабируемые и эффективные решения, которые легко адаптируются под ваши потребности.-->
<!--                        <br>-->
<!--                        <br>-->
<!--                        <b>JavaScript:</b> Для создания интерактивного пользовательского интерфейса мы применяем JavaScript, который обеспечивает динамичное взаимодействие на сайте. Также мы можем использовать Vue.js — современный JavaScript-фреймворк, который позволяет создавать отзывчивые и высокопроизводительные интерфейсы, улучшая пользовательский опыт.-->
<!--                        <br>-->
<!--                        <br>-->
<!--                        <b>1С:</b> Мы интегрируем 1С для автоматизации бизнес-процессов и управления ресурсами компании. Это позволит вам оптимизировать учет и повысить эффективность работы.-->
<!--                        <br>-->
<!--                        <br>-->
<!--                        <b>ERP-аналитика:</b> Мы используем системы ERP-аналитики для глубокого анализа данных и оптимизации процессов. Это поможет вам принимать обоснованные решения на основе актуальной информации и улучшить общую эффективность бизнеса.-->
<!--                    </CollapseItem>-->
                </div>
            </InfoSection>
        </div>

        <footer class="app__footer">
            <div class="footer">
                <div class="footer__rectangle"></div>
                <div class="footer__left">
                    <div class="footer__logo">
                        <LogoWhite />
                    </div>
                    <div class="footer__copyright">
                        © 2024 «Nexta-IT». Все права защищены.
                    </div>
                    <div class="footer__policy">
                        <a href="privacy_policy.html"
                           title="Политика конфиденциальности">
                            Политика конфиденциальности
                        </a>
                    </div>
                </div>
                <div class="footer__right">
                    <div class="footer__contacts">
                        <CompanyInfo footer="Общество с ограниченной ответственностью «Некста»" />
                    </div>
                </div>
            </div>
        </footer>
        <DetailDrawer v-if="isMobileDevice()"
                      :show="detailModal.show"
                      :title="detailModal.title"
                      :image="detailModal.image"
                      :image-size="detailModal.imageSize"
                      :image-position="detailModal.imagePosition"
                      :content="detailModal.content"
                      @close-modal="closeDetailModal"
                      @closed-modal="closedDetailModal" />
        <DetailModal v-else
                     :show="detailModal.show"
                     :title="detailModal.title"
                     :image="detailModal.image"
                     :image-size="detailModal.imageSize"
                     :image-position="detailModal.imagePosition"
                     :content="detailModal.content"
                     @close-modal="closeDetailModal"
                     @closed-modal="closedDetailModal" />
    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import MainPromo from '@/components/MainPromo.vue';
import InfoSection from '@/components/Partials/InfoSection.vue';
import InfoBlock from '@/components/Partials/InfoBlock.vue';
import SimpleInfoBlock from '@/components/Partials/SimpleInfoBlock.vue';
import Cube from '@/components/Partials/Icons/Cube.vue';
import CollapseItem from '@/components/Partials/CollapseItem.vue';
import LogoWhite from '@/components/Partials/LogoWhite.vue';
import Btn from '@/components/Partials/Btn.vue';
import FeedbackForm from '@/components/FeedbackForm.vue';
import CodeIcon from '@/components/Partials/Icons/CodeIcon.vue';
import Chart from '@/components/Partials/Icons/Chart.vue';

import { ElRow, ElCol } from 'element-plus';
import Accept from '@/components/Partials/Icons/Accept.vue';
import CompanyInfo from '@/components/Partials/CompanyInfo.vue';
import DetailModal from '@/components/Partials/DetailModal.vue';
import DetailDrawer from '@/components/Partials/DetailDrawer.vue';

export default {
    name: 'App',
    components: {
        DetailDrawer,
        DetailModal,
        CompanyInfo,
        Accept,
        Chart,
        CodeIcon,
        FeedbackForm,
        Btn,
        CollapseItem,
        Cube,
        SimpleInfoBlock,
        LogoWhite,
        InfoBlock,
        InfoSection,
        MainPromo,
        HeaderComponent,
        ElRow,
        ElCol
    },
    data () {
        return {
            detailModal: {
                show: false,
                title: null,
                image: null,
                imageSize: null,
                imagePosition: null,
                content: null
            }
        };
    },
    methods: {
        closeDetailModal () {
            this.detailModal.show = false;
        },
        closedDetailModal () {
            this.detailModal.title = null;
            this.detailModal.image = null;
            this.detailModal.imageSize = null;
            this.detailModal.imagePosition = null;
            this.detailModal.content = null;
        },
        openDetailModal (title, image, imageSize, imagePosition, detailContent) {
            this.detailModal.show = true;
            this.detailModal.title = title;
            this.detailModal.image = image;
            this.detailModal.imageSize = imageSize;
            this.detailModal.imagePosition = imagePosition;
            this.detailModal.content = detailContent;
        }
    }
};
</script>

<style lang="scss">
.app {
    position: relative;
    &__main-block {
        width: 100%;
        padding: 15px 24px;
        background-repeat: no-repeat;
        background-position-x: center;
        background-image: url(@/assets/worm.jpg);
        box-sizing: border-box;
        background-size: cover;

        @media (min-width: 768px) {
            background-position-y: -150px;
        }
    }
    &__what-developing {
        margin: 0;
    }
    &__feedback-section {
        max-width: 1920px;
        margin: 120px auto;
        padding: 0 24px;
    }
    &__footer {
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 24px;
    }
    &__cases {
        margin: 60px auto;
    }
    &__services-cost {
        margin: 120px auto 60px auto;
    }
}
.faq-section {
    max-width: 1280px;
    margin: 120px auto;
    &__items {
        display: flex;
        flex-direction: column;
        gap: 15px 0;
    }
}
.what-developing {
    max-width: 1920px;
    position: relative;
    margin: 0 auto;
    &__items {
        gap: 10px 0;
    }
}
.cases, .services-cost {
    max-width: 1920px;
    &__items {
        gap: 10px 0;
    }
    &__button {
        margin: 15px 0 0 0;
        width: 100%;
    }
}
.check-list {
    list-style: none;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    &__item {
        display: flex;
        align-items: center;
        gap: 0 8px;
    }
    &__icon {
        min-width: 15px;
        min-height: 15px;
    }
}
.slogan-section {
    background-color: #F2F2F2;
    text-align: center;

    //border-radius: 0 0 20px 20px;
    position: relative;
    &__content {
        padding: 72pt 0;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1920px;
        margin: 0 auto;
        @media (min-width: 768px) {
            padding: 150pt 0;
        }
    }

    //&::before {
    //    content: "";
    //    background-repeat: no-repeat;
    //    background-position-x: center;
    //    background-image: url(@/assets/worm.jpg);
    //    transform: rotate(180deg);
    //    position: absolute;
    //    width: 200px;
    //    height: 100%;
    //    left: 0;
    //    top: 0;
    //    background-size: 400%;
    //}
    //&::after {
    //    content: "";
    //    background-repeat: no-repeat;
    //    background-position: center 317px;
    //    background-image: url(@/assets/worm.jpg);
    //    transform: rotate(270deg);
    //    position: absolute;
    //    width: 750px;
    //    height: 500px;
    //    right: -155px;
    //    top: 0;
    //    background-size: 257%;
    //
    //}

    &__text {
        color: #101010;
        max-width: 400px;
        font-size: 46px;
        font-weight: 500;

        @media (min-width: 768px) {
            max-width: 1200px;
            font-size: 80px;
        }
    }
    &__key-word {
        color: #165BB6;
    }
}
.services {
    background-color: #165BB6;
    background-image: url(@/assets/blue_bg.jpg);
    padding: 150px 24px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__content {
        margin: 0 auto;
        max-width: 1920px;
    }

    &__items {
        max-width: 1920px;
        margin: 0 auto;
        //display: flex;
        //flex-wrap: wrap;
        //justify-content: space-around;
        gap: 10px 0;
    }

    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        height: 60px;
        width: 100%;
        background-color: #F2F2F2;
        display: block;
        border-radius: 0 0 30px 30px;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 60px;
        width: 100%;
        background-color: #F2F2F2;
        display: block;
        border-radius: 30px 30px 0 0 ;
    }
}
.footer {
    background: #458BE8 linear-gradient(135deg, #2AFADF 0%, #4C83FF 100%);
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    &__rectangle {
        opacity: 0.3;
        position: absolute;
        background-image: url(@/assets/blue_bg.jpg);
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    &__left, &__right {
        position: relative;
        z-index: 1;
        padding: 30px;
    }
    &__logo {
        width: 270px;
    }
    &__policy {
        padding: 15px 0;
        & a {
            color: #FFFFFF;
        }
    }
    &__copyright {
        margin: 30px 0 0 0;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
    }
    &__contacts {
        max-width: 500px;
    }
}
.detail-content {
    & > p {
        &:first-child {
            margin-top: 0;
        }
        margin: 15px 0 0 0;
    }
}
</style>
